/* ==========================================================================
   Reset
   ========================================================================== */

html,
body,
main { min-height: 100%; }

img, iframe, object, video {
  max-width: 100%;
  width: auto;
  height: auto;
}

form {
  &:after,
  &:before {
    display: table;
    content: " ";
  }
  &:after { clear: both; }
}

textarea {
  max-width: 100%;
  width: 100%;
}