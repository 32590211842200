/*!
 * Tipografia
 * LandingPage Bodytech
 * Desenvolvido por FattoriaWeb em: 31/10/2017 -- Atualizado 11/03/2024
 */

@font-face {
  font-family: 'icones-bodytech';
  src: url('../fonts/icones-bodytech.eot');
  src: url('../fonts/icones-bodytech.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icones-bodytech.woff') format('woff'),
        url('../fonts/icones-bodytech.ttf')  format('truetype'),
        url('../fonts/icones-bodytech.svg#icones-bodytech') format('svg');
  font-weight: normal;
}

.icones{
  font-family: 'icones-bodytech' !important;
  font-size: inherit;
  font-style: normal !important;
  font-weight: normal !important;
  color: inherit;
  font-display: swap;
}

.icone-equipe:before { content: "\e91c"; }
.icone-aulas-coletivas:before { content: "\e91d"; }
.icone-treinos-ao-vivo:before { content: "\e91e"; }
.icone-convite-para-amigos:before { content: "\e91f"; }
.icone-estrelas:before { content: "\e920"; }
.icone-noite:before { content: "\e921"; }
.icone-manha:before { content: "\e922"; }
.icone-full-day:before { content: "\e923"; }
.icone-tiktok:before { content: "\e924"; }
.icone-instagram:before { content: "\e925"; }
.icone-facebook:before { content: "\e926"; }
.icone-chevron-down:before { content: "\e927"; }
.icone-estrutura-completa:before { content: "\e928"; }
.icone-funcional:before { content: "\e929"; }
.icone-acesso-academia:before { content: "\e92a"; }
.icone-vestiario:before { content: "\e92b"; }
.icone-mais:before { content: "\e92c"; }
.icone-menos:before { content: "\e92d"; }