/* ==========================================================================
  Geral
  ========================================================================== */
body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 16px;
  background: linear-gradient(180deg, #202020 0%, #000 100%);
  color: white;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;

  @media (min-width: 1200px){
    zoom: 0.8;
  }

  @media (min-width: 1800px){
    zoom: 1;
  }
}

.container{

  @include media-min1200{
    padding-left: 0;
    padding-right: 0;
    max-width: 1160px;
  }

  @include media-min1500{
    max-width: 1170px;
  }

  @include media-min1800{
    max-width: 1245px;
  }
}

.valores{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  height: 460px;
  padding: 0 32px;
  border-radius: 6px;
  border: 1px solid #00BC8E;

  @include media-min768{
    height: 360px;
  }

  @include media-min992{
    height: 720px;
    padding: 0 70px;
  }

  &_titulo{
    position: absolute;
    top: -17px;
    left: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 20px;
    background-color: #00BC8E;
    border-radius: 6px;
    border: 1px solid #00BC8E;
    transform: translateX(-50%);
    white-space: nowrap;

    @include media-min992{
      top: -33px;
      height: 66px;
      font-size: 48px;
      padding-left: 33px;
      padding-right: 33px;
    }
  }

  &_wrapper{
    display: none;

    &.active{
      display: flex;
    }
  }

  .form{
    flex-direction: column;

    @include media-min768{
      flex-direction: row;
      align-items: center;
    }

    .info{
      text-align: center;

      @include media-min768{
        text-align: left;
      }
    }

    .titulo{
      margin-bottom: 13px;
      font-size: 14px;

      @include media-min768{
        max-width: 120px;
      }

      @include media-min992{
        margin-bottom: 16px;
        font-size: 22px;
        max-width: 180px;
      }

      @include media-min1200{
        margin-bottom: 22px;
        font-size: 28px;
        max-width: 230px;
      }

      @include media-min1800{
        font-size: 30px;
        max-width: 247px;
      }

      &_destaque{
        font-size: 17px;

        @include media-min992{
          font-size: 28px;
        }

        @include media-min1200{
          font-size: 35px;
        }

        @include media-min1800{
          font-size: 38px;
        }
      }
    }

    .extra{
      margin-bottom: 13px;
      font-size: 10px;
      font-weight: 800;
      opacity: 0.5;

      @include media-min992{
        margin-bottom: 0;
        font-size: 22px;
        opacity: 1;
      }

      @include media-min1200{
        font-size: 28px;
      }

      @include media-min1800{
        font-size: 30px;
      }
    }

    &_filtro{
      display: flex;
      flex-direction: column;

      @include media-min768{
        margin-left: 80px;
        width: 250px;
      }

      @include media-min992{
        width: 350px;
      }

      @include media-min1200{
        width: 470px;
      }

      &_label{
        display: block;
        font-size: 16px;
        font-weight: 700;

        @include media-min992{
          margin-bottom: 5px;
          font-size: 22px;
        }

        @include media-min1200{
          font-size: 34px;
        }

        @include media-min1800{
          font-size: 36px;
        }

        &:nth-of-type(2){
          margin-top: 20px;

          @include media-min992{
            margin-top: 15px;
          }
        }
      }

      .select2-container--default .select2-selection--single{
        height: 28px;
        background-color: transparent;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #FFF;

        @include media-min992{
          height: 56px;
          font-size: 22px;
        }

        @include media-min1200{
          height: 62px;
          font-size: 29px;
        }

        @include media-min1500{
          font-size: 30px;
        }
        
        @include media-min1800{
          font-size: 32px;
        }

        .select2-selection__rendered{
          padding-right: 32px;
          padding-left: 8px;
          line-height: 28px;
          color: white;

          @include media-min992{
            padding-left: 18px;
            line-height: 56px;
          }

          @include media-min1200{
            line-height: 62px;
          }
        }

        .select2-selection__arrow{
          top: 50%;
          right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: initial;
          height: initial;
          width: initial;
          transform: translateY(-50%);

          @include media-min992{
            right: 18px;
          }

          &::before{
            content: '\e927';
            font-family: 'icones-bodytech' !important;
            font-size: 8px;

            @include media-min992{
              font-size: 16px;
            }
          }

          b{ display: none; }
        }

        .select2-selection__placeholder{
          color: white;
          opacity: 0.5;
        }
      }

      .btn{
        margin-top: 18px;
        margin-left: auto;
        margin-right: auto;

        @include media-min992{
          margin-top: 55px;
          margin-right: initial;
        }
      }
    }
  }

  &_lista{
    margin-bottom: 20px;

    @include media-min992{
      margin-bottom: 21px;
    }

    &_item{
      font-size: 11px;

      @include media-min992{
        display: flex;
        align-items: center;
        column-gap: 12px;
        font-size: 22px;
      }

      @include media-min1200{
        column-gap: 18px;
        font-size: 28px;
      }

      @include media-min1500{
        font-size: 30px;
      }

      .icones{
        display: none;

        @include media-min992{
          display: block;
        }
      }

      & + .valores_lista_item{
        margin-top: 7px;

        @include media-min992{
          margin-top: 16px;
        }
      }
    }
  }

  .loading{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .spinner-border{
      width: 100px;
      height: 100px;
    }
  }

  .resultado{
    flex-direction: column;
    align-self: flex-start;
    margin-top: 64px;
    width: 100%;

    @include media-min768{
      margin-top: 32px;
    }

    @include media-min992{
      margin-top: 87px;
    }

    &_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;
    }

    .titulo{
      font-size: 18px;

      @include media-min992{
        font-size: 40px;
      }
    }

    .btn{
      align-self: flex-end;
    }
  }

  .accordion-valores{
    overflow: auto;
    height: 215px;
    margin-bottom: 26px;

    @include media-min768{
      height: 200px;
      margin-bottom: 20px;
    }

    @include media-min992{
      height: 400px;
      margin-bottom: 40px;
    }

    .accordion-item{
      background-color: transparent;
      border: 1px solid #00BC8E;
      border-radius: 6px;
      transition: all ease 0.2s;

      &.active{
        background-color: #000000;
        border-color: white;
      }

      & + .accordion-item{
        margin-top: 9px;

        @include media-min768{
          margin-top: 20px;
        }
      }
    }

    .accordion-button{
      padding: 6px 8px;
      background-color: transparent;
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
      border: none;
      box-shadow: none;
      border-radius: 6px 6px 0 0;

      @include media-min768{
        padding: 8px 12px;
        font-size: 18px;
      }
  
      @include media-min992{
        padding: 14px 17px;
        font-size: 32px;
      }

      &::after{
        content: '\e92d';
        width: auto;
        height: initial;
        font-family: 'icones-bodytech';
        font-size: 14px;
        background-image: none;

        @include media-min768{
          font-size: 20px;
        }
    
        @include media-min992{
          font-size: 24px;
        }
      }

      &:focus{
        box-shadow: none;
        outline: none;
      }

      &.collapsed{
        &::after{
          content: '\e92c';
        }
      }
    }

    .accordion-body{
      padding: 0;
    }

    &_lista{
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;

      &_item{
        display: flex;
        align-items: center;
        color: white;
        padding: 10px 16px 13px;        
    
        @include media-min992{
          padding: 20px 35px;
        }

        &_intro {
          @media(min-width: 992px){
            display: flex;
            align-items: center;
          }
        }

        &_values {             
          white-space: nowrap;          
        }

        .icones{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          margin-right: 10px;
          color: #00BC8E;
          font-size: 14px;

          @include media-min768{
            width: 20px;
            margin-right: 12px;
            font-size: 20px;
          }
      
          @include media-min992{
            width: 50px;
            margin-right: 35px;
            font-size: 40px;
          }
        }

        &_desc{
          margin-bottom: 0;
          flex: 1;
          font-size: 8px;
          font-weight: 500;

          @include media-min768{
            font-size: 10px;
          }
      
          @include media-min992{
            font-size: 16px;
          }

          &_titulo{
            display: block;
            font-size: 10px;
            font-weight: 700;

            @include media-min768{
              font-size: 16px;
            }
        
            @include media-min992{
              font-size: 32px;
            }
          }

          &_texto{
            display: block;
            width: 90%;

            @include media-min768{
              width: 60%;
            }
        
            @include media-min992{
              width: 70%;
            }
          }
        }

        .disclaimer {
          display: block;
          line-height: normal;
          font-size: 8px;
          font-weight: 500;

          @include media-min992{
            font-size: 16px;
          }
        }

        &_valor{
          display: flex;
          align-items: baseline;
          margin-bottom: 0;
          font-weight: 700;
          font-variant-numeric: lining-nums proportional-nums;

          &_cifrao{
            display: block;
            margin-right: 6px;
            align-self: center;
            font-size: 6px;

            @include media-min768{
              font-size: 12px;
            }
        
            @include media-min992{
              font-size: 20px;
            }
          }

          &_numero{
            line-height: 1;
            font-size: 18px;

            @include media-min768{
              font-size: 30px;
            }
        
            @include media-min992{
              font-size: 62px;
            }
          }

          &_mes{
            font-size: 6px;

            @include media-min768{
              font-size: 12px;
            }
        
            @include media-min992{
              font-size: 20px;
            }
          }
        }

        &:nth-of-type(odd){
          background-color: rgba(white, 0.1);
        }
      }
    }
  }
}

.select2-container--default .select2-results__option--selectable{
  padding: 4px 8px;
  font-size: 14px;
  color: #000;
  font-weight: 500;

  @include media-min992{
    padding: 10px 20px;
    font-size: 22px;
  }

  @include media-min1200{
    font-size: 29px;
  }

  @include media-min1500{
    font-size: 30px;
  }

  @include media-min1800{
    font-size: 32px;
  }

  &.select2-results__option--highlighted{
    background: #00BC8E;
    font-weight: 700;
    border: 1px solid #FFF;
  }
}

.gradient_holder {
  background: linear-gradient(0deg, #00BC8E 0%, rgba(0, 188, 142, 0.00) 100%);
}

.vontade-comecar_sect {
  overflow: hidden;
  padding-top: 40px;

  @media(min-width: 1200px){
    padding-top: 103px;
  }

  @media(min-width: 1500px){
    padding-top: 108px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .titulo {
      line-height: 1;

      &.h3 {
        @media(max-width: 991px){
          font-size: 31px;
        }
      }
      
      &.h1 {
        margin-bottom: 16px;

        @media(min-width: 1200px){
          margin-bottom: 42px;
        }        
      }
    }

    .intro_text {
      color: #FFF; 
      font-size: 8px;   
      font-weight: 600;
      line-height: normal;
      margin: 24px 0 90px;

      @media(min-width: 1200px){
        font-size: 20px;
        margin: 61px 0 93px;
      } 

      @media(min-width: 1500px){
        margin: 61px 0 156px;
      } 
    }
  }

  .image_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .image {
      min-width: 466px;
    }

    .flutuante {
      position: absolute;

      &.smile {
        left: 0;
        top: -22px;
        width: 53px;

        @media(min-width: 1200px){
          width: initial;
          left: 37px;
          top: 177px;
        }
      }

      &.hand {
        right: 0;
        bottom: 44px;
        width: 46px;

        @media(min-width: 1200px){
          width: initial;
          right: 38px;
          bottom: 225px;
        }
      }

      &.estrela-small {
        left: 0;
        bottom: 121px;
        width: 17px;

        @media(min-width: 1200px){
          width: initial;
          left: 177px;
          bottom: initial;
          top: 37px;
        }
      }

      &.estrela {
        display: none;

        @media(min-width: 1200px){
          display: block;       
          right: 34px;      
          bottom: 424px;
        }
      }

      &.estrelas {
        left: 55%;
        bottom: 40px;
        width: 48px;

        @media(min-width: 1200px){
          width: initial;       
          bottom: 104px;        
        }
      }
    }
  }
}

.banner_sect {
  padding: 38px 0 48px;

  @media(min-width: 1200px){
    padding: 65px 0 106px;
  }

  @media(min-width: 1500px){
    padding: 83px 0 98px;
  }

  .container {
    @media(min-width: 1200px){
      display: flex;
      align-items: flex-start;
      gap: 150px;
      justify-content: space-between;
    }

    @media(min-width: 1500px){
      gap: 85px;
    }

    .banner_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media(min-width: 1200px){
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 40px;
      }   
      
      .subtitulo{
        color: white;
        font-size: 27px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 28px;
        width: 80%;
        text-align: center;

        @media(min-width: 1200px){
          width: 100%;
          margin-bottom: 29px;
          text-align: left;
        }
      }
    }
  }

  .logo {
    display: block;
    max-width: 110px;
    margin-bottom: 21px;

    @media(min-width: 1200px){
      max-width: initial;
      margin-bottom: 74px;
    }

    @media(min-width: 1500px){    
      margin-bottom: 88px;
    }
  }

  .titulo {  
    line-height: 1;

    &.h4 {
      @media(max-width: 1199px){
        font-size: 40px;
      }
    }
  }

  .banner_image {
    max-width: 245px;

    @media(min-width: 1200px){
      max-width: 389px;
    }

    @media(min-width: 1500px){
      max-width: 570px;
    }
  }

  .estrelas-brancas {
    font-size: 38px;
  }

  .btns-wrapper {
    
    @media(min-width: 1200px){
      display: flex;
      align-items: center;
      gap: 18px;

      // margin-top: 35px;
    }
  }
  
  .tag {
    max-width: 360px;
    border-radius: 13px;
    border: 1px solid #00BC8E;
    padding: 10px 30px;
    margin: 47px 0 21px;
    text-align: center;

    color: #FFF;
    font-size: 13px;  
    font-weight: 500;
    line-height: normal;

    @media(min-width: 1200px){
      width: max-content;
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: initial;
      margin-top: 0;
      margin-bottom: 25px;
   
      font-size: 21px;   
      font-weight: 700;
      
      padding: 2px 13px;
      border-radius: 21px;
    }

    .big-values {
      color: #FFF;  
      font-size: 29px;   
      font-weight: 700;
      line-height: normal;
    }
  }
}

.section-beneficios{
  margin-bottom: 56px;

  @media (min-width:1200px){
    margin-bottom: 110px;
  }

  @media (min-width:1500px){
    margin-bottom: 160px;
  }
}

.card-beneficios{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 34px;

  @media (min-width:992px){
      gap: 14px;
      margin-bottom: 52px;
  }

  .wrapper-icones{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 42px;

    @media (min-width:992px){
      height: 66px;
    }
  }

  .icones{
    color: #00BC8E;
    line-height: normal;

    &.amigos{
      font-size: 28px;
  
      @media (min-width:992px){
         font-size: 43px;
      }
    }
    &.treinos{
      font-size: 37px;
  
      @media (min-width:992px){
         font-size: 58px;
      }
    }
    &.aulas{
      font-size: 42px;
  
      @media (min-width:992px){
         font-size: 66px;
      }
    }
    &.equipe{
      font-size: 37px;
  
      @media (min-width:992px){
         font-size: 60px;
      }
    }
    &.vestiario{
      font-size: 37px;
  
      @media (min-width:992px){
         font-size: 58px;
      }
    }
  }
  
  .paragrafo{
    color: #A8A8A8;
    text-align: center;
    font-size: 12.286px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    width: 75%;

    @media (min-width: 768px) {
       width: 50%;
    }

    @media (min-width:992px) {
      font-size: 19.24px;
       width: 95%;
    }
    @media (min-width: 1500px) {
       width: 90%;
    }
  }
}