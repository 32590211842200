/* ==========================================================================
    Títulos
   ========================================================================== */


.titulo{
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;

    &.h1{
        font-size: 35px;
        font-weight: 900;

        @media (min-width: 992px){
          font-size: 96px;
        }
    }
    &.h2{
        font-size: 44px;
        font-weight: 900;

        @media (min-width: 992px){
            font-size: 80px;
        }

        @media (min-width: 1800px){
            font-size: 90px;
        }
    }
    &.h3{
        font-size: 40px;

        @media (min-width: 992px){
            font-size: 86px;
        }
    }
    &.h4{
        font-size: 32px;

        @media (min-width: 992px){
            font-size: 80px;
        }
    }
    &.h5{
        font-size: 36px;
        font-weight: 800;
    }
    &.h6{
        font-size: 18px;

        @media (min-width: 992px){
            font-size: 28px;
        }
    }
}