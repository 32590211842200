// Medias Queries
@mixin media-max320 {
    @media screen and (max-width:320px){
        @content;
    }
}

@mixin media-max479 {
    @media screen and (max-width:479px){
        @content;
    }
}

@mixin media-min480 {
    @media screen and (min-width:480px){
        @content;
    }
}

@mixin media-max767 {
    @media screen and (max-width:767px){
        @content;
    }
}

@mixin media-min768 {
    @media screen and (min-width:768px){
        @content;
    }
}

@mixin media-max991 {
    @media screen and (max-width:991px){
        @content;
    }
}

@mixin media-min992 {
    @media screen and (min-width:992px){
        @content;
    }
}

@mixin media-min1200 {
    @media screen and (min-width:1200px){
        @content;
    }
}

@mixin media-min1500 {
    @media screen and (min-width:1500px){
        @content;
    }
}

@mixin media-min1800 {
    @media screen and (min-width:1800px){
        @content;
    }
}
