/* ==========================================================================
    Footer
   ========================================================================== */


.footer {
    width: 100%;
    padding: 20px 0;
    background-color: black;

    @media(min-width: 1200px) {
        padding: 40px 0;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        @media(min-width: 1200px){
            flex-direction: row;
            justify-content: space-between;
        }

        .footer_logo {
            max-width: 71px;

            @media(min-width: 1200px){
                max-width: initial;
            }
        }

        .social-media-links_list {
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0;

            @media(min-width: 1200px){
                gap: 34px;
            }

            .social-media_link {
                color: white;
                text-decoration: none;
                font-size: 24px;

                transition: 0.3s ease;

                @media(min-width: 1200px){
                    font-size: 54px;
                }

                @media(min-width: 1500px){
                    font-size: 60px;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}