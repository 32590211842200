/* ==========================================================================
    Botões
   ========================================================================== */


.btn{
    padding: 0 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    border-radius: 6px;
    transition: 0.3s ease;
    font-family: "Raleway", sans-serif;
    height: 43px;

    @media (min-width: 992px){
        font-size: 24px;
    }

    &.btn-verde{
        background-color: #00BC8E;

        &:hover{
            background-color: darken( #00BC8E, 10%);
        }
    }

    &.outline-verde{
        padding: 0 17px;
        background-color: transparent;
        border: 1px solid #00BC8E;

        &:hover{
            background-color: #00BC8E;
        }
    }

    &.medio{
        height: 28px;
        padding: 0 12px;
        font-size: 16px;
        border-radius: 4px;

        @media (min-width: 992px){
            height: 59px;
            padding: 0 24px;
            font-size: 32px;
            border-radius: 8px;
        }
    }

    &.grande{
        padding: 0 12px;
        border-radius: 4px;
        background-color: transparent;
        font-size: 18px;
        height: 29px;

        @media (min-width: 992px) {
            padding: 0 33px;
            border-radius: 11px;
            font-size: 48px;
            height: 82px;
        }
    }
}