// Css raiz do projeto

/*!
 * LandingPage Bodytech
 * Desenvolvido por FattoriaWeb em: 31/10/2017 -- Atualizado 11/03/2024
 */

// Tipografia
@import "_tipografia.scss";

// Geral
@import "geral/_reset.scss";
@import "geral/_medias.scss";
@import "geral/_geral.scss";

// Componentes
@import "componentes/_btns.scss";
@import "componentes/_titulos.scss";
@import "componentes/_footer.scss";

// Paginas
